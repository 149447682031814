
import { Component, OnInit, AfterViewInit, ElementRef} from '@angular/core';


@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']

})
export class InfoComponent implements OnInit, AfterViewInit {
  cols = 1;
  responsive = true;
constructor(private elementRef: ElementRef) {
        
    }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = 'lightgrey';
 }

}
