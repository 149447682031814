import { Apartment } from './../tools/apartments';
import { DateRange } from './../tools/daterange';
import { CheckAvailable, Prices } from './../tools/checkavailable';
import { DateService } from './../shared/services/date.service';
import { ApartmentData } from './../tools/apartmentdata.model';
import { AppartmentDetails } from './../tools/appartment-details.model';
import { Utils } from './../tools/utils';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core'; 
import { Observable, Subscription  } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireDatabase } from '@angular/fire/database';
import { StateService } from './../shared/services/state.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { ConstantPool } from '@angular/compiler';


@Component({
  selector: 'app-available',
  templateUrl: './available.component.html',
  styleUrls: ['./available.component.scss']
})
export class AvailableComponent implements OnInit {

  dateString: string;
  arrivalDate: string;
  departureDate: string;
  urlAvailable = 'https://us-central1-nodesil-91870.cloudfunctions.net/available?arrivalDate='; 
  urlApartments = 'https://us-central1-nodesil-91870.cloudfunctions.net/smoobu/apartments'; 
  mapExtraPP: Map<number, number> = new Map();
  mapMinPeople: Map<number, number> = new Map();
  apartmentsDataPerId: Map<string, ApartmentData> = new Map();
  arrayAvailable: number[];
  pricesForAppartment: any;
  itemsDb: Observable<any>;
  allItemsFromDB: Map<string, any>;
  selectedItem: any;
  post: Observable<any>;
  fotoForId: Map<string, any> = new Map();
  dateRange: DateRange;
  isExpanded = true;
  isShowing = false;
  noHouses: boolean;
  arrayGuests: number[];
  strArrayGuests: string[];
  amountGuestsControl = new FormControl();
  guestOptions: Observable<string[]>;
  startDate = new FormControl();
  endDate = new FormControl();
  amountGuests: number;

  diffDays: number;
  validDate: boolean;
  apartmentPrice: number;
  pricesPerAppartment: Map<string, Prices>;
  dataFromDB: Observable<any>;
  extraPriceRaw: number;
  numberOfGuests: number;
  finalPrice:number;
  maxPeople: number;
 
  errorMessage: string;
  policyCheckbox: boolean;
  priceCheck: boolean;
  dateArrival: Date;
  dateDeparture: Date;
  finalPricesPerAppartment = new Map();
  finalPricesPerDay = new Map();

  cols = 1;
  responsive = true;
  
  constructor(private router:Router, private formBuilder: FormBuilder, private state: StateService, private dateService: DateService, private http: HttpClient, private storage: AngularFireStorage, private afDatabase: AngularFireDatabase) { 
    
  }

  ngOnInit(): void {
    this.generateUrl();
    this.arrayGuests = this.arrayGuests = Array.from(Array(8), (_, i) => i + 1);
    this.strArrayGuests = this.arrayGuests.map(function(e){return e.toString()})
  }

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  generateUrl(){
    this.dateRange = this.dateService.getDateRange();
    let dateArrival = new Date(this.dateRange.admDateRange.startDate);
    let dateDeparture = new Date(this.dateRange.admDateRange.endDate);
    this.amountGuests = this.dateRange.amountGuestsControl;
    let monthA = new String(dateArrival.getMonth() + 1);
    let monthD = new String(dateDeparture.getMonth() + 1);
    let dayA = new String(dateArrival.getDate());
    let dayD  = new String(dateDeparture.getDate());

    if (dateArrival.getMonth() < 9)
    {
     monthA = '0' + monthA
    }

    if (dateDeparture.getMonth() < 9)
    {
     monthD = '0' + monthD
    }

    if (dateArrival.getDate() < 10){
      dayA = '0' + dayA
    }

    if (dateDeparture.getDate() < 10){
      dayD = '0' + dayD
    }

    this.arrivalDate = dateArrival.getFullYear() + '-' +monthA + '-' + dayA;
    this.departureDate = dateDeparture.getFullYear() + '-' +monthD + '-' + dayD;
    
    this.urlAvailable = this.urlAvailable + this.arrivalDate + '&departureDate=' + this.departureDate;
    this.getAvailableApartmentData();
  }
 
  getDBList(arrayAvailable: number[]){
    this.itemsDb = this.afDatabase.list('apartments').valueChanges();
    const itemsDBSub: Subscription = this.itemsDb.subscribe((apartmentData: ApartmentData[]) =>
    {
      const listWithImageLinks = apartmentData.map((apartment: ApartmentData) => {
         // for every id, prepare apartment detail request
         const id = ''+ apartment.id      
        this.apartmentsDataPerId.set(id, apartment);

        const allImgIds: Observable<any>[] = this.getAllImageLinksForId(apartment.id, apartment.imgcount);
        return {...apartment,
         allImgIds: allImgIds}
      });
      const hasEnoughPeople = (apartmentData: ApartmentData, numberOfPeople: number) => apartmentData.maxOccupancy >= numberOfPeople;
      const apartmentsDataPerId = this.apartmentsDataPerId;
      const numberOfPeople =  this.dateRange.amountGuestsControl;
      if (numberOfPeople) {
         this.arrayAvailable = this.arrayAvailable.filter((id: number) => {
        const idS = id+'';
        const data = apartmentsDataPerId.get(idS);
        return data && hasEnoughPeople(data, numberOfPeople);
      })
      }
     

     this.allItemsFromDB = Utils.transformArrayKeyToMap(listWithImageLinks, 'id');
     itemsDBSub.unsubscribe();
    })
  }

  getAllApartmentsData(){
    this.post = this.http.get(this.urlApartments);
    const subscription: Subscription = this.post.subscribe((response: {apartments: {id: string, name: string}[]}[]) => {
    const { apartments } = response[0];
    const allIds = apartments.map((a) => a.id);
    for (const id of allIds) {
      const firstFotoForThisId = this.getRefForIdIndex(id);
      if (Boolean(firstFotoForThisId)) {
        this.fotoForId.set(id, firstFotoForThisId);
      }
    }
    subscription.unsubscribe();
    });
  }

  filterAvailable(apartments: any[]): any[] {
    return apartments.filter((apartment) => this.arrayAvailable.includes(apartment.id));
  }

  getAllImageLinksForId(apartmentId: any, imgCount: number, ): any[] {
    const arrayFromLength = new Array(imgCount);
    const arrayWithRef = arrayFromLength.map((item, index) => this.getRefForIdIndex(apartmentId, (index + 1)));
    const allImageLinks = [];
     for (let i = 0; i < imgCount; i++) {
       const imageLink = this.getRefForIdIndex(apartmentId, i + 1);
       if (Boolean(imageLink)) {
         allImageLinks.push(imageLink);
       }
     }
     return allImageLinks;
   }

  async getAvailableApartmentData(){
    this.dataFromDB = this.afDatabase.list('/apartments').valueChanges();
    const itemsDBSub: Subscription = this.dataFromDB.subscribe((apartmentData: ApartmentData[]) =>
    {
      
      const apartmentMap = apartmentData.map((apartment: ApartmentData) => {
        this.mapExtraPP.set(apartment.id, apartment.extraPP);
        this.mapMinPeople.set(apartment.id, apartment.minPeople);
      });
      this.getAvailableApartmentData;
    itemsDBSub.unsubscribe();
    })
    await this.http.get(this.urlAvailable).toPromise().then((checkAvailable: CheckAvailable) => { 
      const numberOfPeople =  this.dateRange.amountGuestsControl;
      const pricesPerAppartment = new Map();
      this.arrayAvailable = checkAvailable.availableApartments;
      for (const apartmentID of this.arrayAvailable) {
        let ExtraPP = this.mapExtraPP.get(apartmentID);
        let minPeople = this.mapMinPeople.get(apartmentID);
          const appartmentKey = ''+apartmentID;
        const priceData = checkAvailable.prices[appartmentKey];
        if (priceData) {
          pricesPerAppartment.set(appartmentKey, priceData);
        }
        const priceDataForSelectedAppartment = pricesPerAppartment.get(appartmentKey);
        this.apartmentPrice = priceDataForSelectedAppartment.price;
        let dateArrival = new Date(this.dateRange.admDateRange.startDate);
        let dateDeparture = new Date(this.dateRange.admDateRange.endDate);
        let diffDays = Math.floor((dateDeparture.getTime() - dateArrival.getTime()) / (1000 * 60 * 60 * 24));
        if (minPeople < numberOfPeople){
          let difPeople = numberOfPeople - minPeople;
          let extraPrice = difPeople * ExtraPP;
          if (diffDays > 29){
            let DiscountExtra = (extraPrice*0.7)*diffDays;
            this.apartmentPrice = this.apartmentPrice + DiscountExtra;
          }
          else if (diffDays > 13){
            let DiscountExtra = (extraPrice*0.8)*diffDays;
            this.apartmentPrice = this.apartmentPrice + DiscountExtra;
          }
          else if (diffDays > 6){
            let DiscountExtra = (extraPrice*0.9)*diffDays;
            this.apartmentPrice = this.apartmentPrice + DiscountExtra;
          }
          else{
            let DiscountExtra = (extraPrice)*diffDays;
            this.apartmentPrice = this.apartmentPrice + DiscountExtra;
          }
        }
        let finalPrice = (this.apartmentPrice)*1.05;

      finalPrice = Math.round(finalPrice);
      let pricePerDay = (finalPrice/diffDays);
      pricePerDay = Math.round(pricePerDay);
      this.finalPricesPerAppartment.set(apartmentID, finalPrice);
      this.finalPricesPerDay.set(apartmentID, pricePerDay);
        } 
    });

      if (this.arrayAvailable.length == 0){
        this.noHouses = true;
      }
      else{
        this.noHouses = false;
        this.getDBList(this.arrayAvailable);
        this.getAllApartmentsData();
      }
    }

  getRefForIdIndex(id: string, index = 1): Observable<any> {
    let downloadUrl;
    try {
     const ref = this.storage.ref(id + '/house' + index + '.jpg');
     downloadUrl = ref.getDownloadURL();
    } catch(e) {
      console.error(e);
    }
   return downloadUrl;
   }

   getApartmentDetailedDataForId(id: string): Observable<any | AppartmentDetails[]> {
    return this.http.get(this.urlApartments + '?id=' + id);
  }

  showApartmentWithId(selectedId: any) {
    this.state.apartmentID = selectedId;
    this.state.apartmentIDArray = this.arrayAvailable;
   }

 

}
