// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase : {
    apiKey: 'AIzaSyBOsfRv5lkx_nln9QNvp8S73_X8eJME8Wc',
    authDomain: 'stay-in-leonidio.firebaseapp.com',
    databaseURL: 'https://stay-in-leonidio.firebaseio.com',
    projectId: 'stay-in-leonidio',
    storageBucket: 'stay-in-leonidio.appspot.com',
    messagingSenderId: '166505503737',
    appId: '1:166505503737:web:b9060f5d40d1007f287b28',
    measurementId: 'G-3ZSSE4R24M'
  }
  
  
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
