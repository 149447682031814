import { SidenavService } from './../shared/services/sidenav.service';
import { UrlService } from './../shared/services/url.service';
import { AuthService } from './../shared/services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { DateRange } from '../tools/daterange';
import { BookService } from './../shared/services/book.service';
import { DateService } from './../shared/services/date.service';
import { ApartmentData } from './../tools/apartmentdata.model';
import { Utils } from './../tools/utils';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core'; 
import { Observable, Subscription  } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireDatabase  } from '@angular/fire/database';
import { StateService } from './../shared/services/state.service';
import { FormControl } from '@angular/forms';
import { FormBuilder, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { CheckAvailable, Prices } from './../tools/checkavailable';
import { Router } from '@angular/router';
import { SafeHtmlPipe } from './../tools/sanitizer.pipe';

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss']
})
export class BookComponent implements OnInit {
  bookID:any;
  sideNavList: Observable<any>;
  bookIDList: Observable<any>;
  imgList: Observable<any>[];
  allItemsFromDB: Map<string, any>;
  allImgIds: Observable<any>;
  url = 'https://us-central1-nodesil-91870.cloudfunctions.net/smoobu/apartments';
  fotoForId: Map<string, any> = new Map();
  selectedItem: any;
  arrayApID: number[];
  isLoggedIn: boolean;
  arrivalControl = new FormControl();
  departureControl = new FormControl();
  amountGuestsControl = new FormControl();
  optionsArrival: string[] = ['14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '0:00', '1:00', '2:00'];
  optionsDeparture: string[] = ['6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00'];
  arrivalOptions: Observable<string[]>;
  departureOptions: Observable<string[]>;
  guestOptions: Observable<string[]>;
  dateRange: DateRange;
  dateArrival: Date;
  dateDeparture: Date;
  arrivalDateString: string;
  departureDateString: string;
  amountGuestsNumber: number;
  priceNumber:number;
  firstNameString:string;
  lastNameString:string;
  phoneString:string;
  countryString:string;
  arrivalTimeString:string;
  departureTimeString:string;
  startDate = new FormControl();
  endDate = new FormControl();
  comment:string;
  cols = 1;
  responsive = true;
  urlReservation: string = 'https://us-central1-nodesil-91870.cloudfunctions.net/newReservation?arrivalDate=' ;
  urlAvailable:string;  
  bookForm;
  diffDays: number;
  arrayAvailable: number[];
  validDate: boolean;
  apartmentPrice: number;
  pricesPerAppartment: Map<string, Prices>;
  dataFromDB: Observable<any>;
  extraPriceRaw: number;
  numberOfGuests: number;
  finalPrice:number;
  maxPeople: number;
  arrayGuests: number[];
  strArrayGuests: string[];
  errorMessage: string;
  policyCheckbox: boolean;
  priceCheck: boolean;
  events: string[] = [];
  opened: boolean;
  isExpanded = true;
  isShowing = false;

  constructor(public safeHtmlPipe: SafeHtmlPipe, public sideNavService: SidenavService, public router: Router, private urlService: UrlService, private dateservice: DateService, private authService: AuthService ,private afAuth: AngularFireAuth, private bookService:BookService, private formBuilder: FormBuilder, private dateService: DateService ,private http: HttpClient, private storage: AngularFireStorage, private afDatabase: AngularFireDatabase, private state: StateService){}
  
  ngOnInit(): void{
    this.bookID = this.state.apartmentID;
    this.arrayApID = this.state.apartmentIDArray;
    this.isLoggedIn = this.state.loggedIn;
    this.sideNavService.setSideNav(true);
    this.setupForm();
   
    this.sideNavList = this.afDatabase.list('/apartments').valueChanges();
    this.showApartment(this.bookID);
    this.arrivalOptions = this.arrivalControl.valueChanges.pipe(
      startWith(''),
      map(arrival => this._filterArrivalTime(arrival))
    );
    this.departureOptions = this.departureControl.valueChanges.pipe(
      startWith(''),
      map(departure => this._filterDepartureTime(departure))
    );
    this.getDates();
  }

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  getDates(){
    this.dateRange = this.dateService.getDateRange();
    if (this.dateRange !== undefined){
      this.bookForm.controls['admDateRange'].controls['startDate'].setValue(this.dateRange.admDateRange.startDate);
      this.bookForm.controls['admDateRange'].controls['endDate'].setValue(this.dateRange.admDateRange.endDate);
    } 
    
  }

  openSite(siteUrl) {
    window.open( siteUrl, '_blank');
 }

  updatePrice(){
    this.requiredFields();
    if (this.bookForm.controls['admDateRange'].controls['startDate'].value != "" && this.bookForm.controls['admDateRange'].controls['endDate'].value != "" && this.bookForm.controls['admDateRange'].controls['startDate'].value !== null && this.bookForm.controls['admDateRange'].controls['endDate'].value !== null ){
      if (this.bookForm.controls['amountGuestsControl'].value != "" && this.bookForm.controls['amountGuestsControl'].value !== null){
        this.priceCheck = true;
        this.errorMessage = "";
        var GAS = this.bookForm.controls['amountGuestsControl'].value;
        this.numberOfGuests = + GAS;
        this.dateService.saveDate(this.bookForm.value);
        this.dataFromDB = this.afDatabase.list('/apartments').valueChanges();
        const itemsDBSub: Subscription = this.dataFromDB.subscribe((apartmentData: ApartmentData[]) =>
        {
          const apartmentMap = apartmentData.map((apartment: ApartmentData) => {
              this.getPricePerPerson(apartment.id, apartment.extraPP, apartment.minPeople, apartment.maxOccupancy);
          });
        itemsDBSub.unsubscribe();
        })
      }
      else{
        this.errorMessage = "Select number of guests";
        this.priceCheck = false;
      }
    }
    else {
      this.errorMessage = "Date not selected";
      this.priceCheck = false;
    }
  }

  requiredFields(){
    if (this.bookForm.controls['firstName'].value != "" && this.bookForm.controls['firstName'].value !== null &&
    this.bookForm.controls['lastName'].value != "" && this.bookForm.controls['lastName'].value !== null && 
    this.bookForm.controls['country'].value != "" && this.bookForm.controls['country'].value !== null &&
    this.bookForm.controls['phone'].value != "" && this.bookForm.controls['phone'].value !== null &&
    this.bookForm.controls['price'].value != "" && this.bookForm.controls['price'].value !== null
    ){
      this.policyCheckbox = true;
    }
    else{
      this.policyCheckbox = false;
    }
  }
  
  getPricePerPerson(id, extraPP, minPeople, maxOccupancy){
    if (id == this.bookID){
        
        if (this.numberOfGuests > minPeople){
          this.extraPriceRaw = (this.numberOfGuests - minPeople);
          this.extraPriceRaw = this.extraPriceRaw * extraPP;
        }
        else{
          this.extraPriceRaw = 0;
        }
        this.calculatePrice();
      }
    }

  calculatePrice(){
    
    this.dateRange = this.dateService.getDateRange();
    let dateArrival = new Date(this.dateRange.admDateRange.startDate);
    let dateDeparture = new Date(this.dateRange.admDateRange.endDate);
    let monthA = new String(dateArrival.getMonth() + 1);
    let monthD = new String(dateDeparture.getMonth() + 1);
    let dayA = new String(dateArrival.getDate());
    let dayD  = new String(dateDeparture.getDate());

    if (dateArrival.getMonth() < 9){monthA = '0' + monthA}
    if (dateDeparture.getMonth() < 9){monthD = '0' + monthD}
    if (dateArrival.getDate() < 10){dayA = '0' + dayA}
    if (dateDeparture.getDate() < 10){dayD = '0' + dayD}

    this.arrivalDateString = dateArrival.getFullYear() + '-' +monthA + '-' + dayA;
    this.departureDateString = dateDeparture.getFullYear() + '-' +monthD + '-' + dayD;

    this.urlAvailable = 'https://us-central1-nodesil-91870.cloudfunctions.net/available?arrivalDate=' + this.arrivalDateString + '&departureDate=' + this.departureDateString;
    const subscription: Subscription = this.http.get(this.urlAvailable).subscribe((checkAvailable: CheckAvailable) => {
      const pricesPerAppartment = new Map();
      this.arrayAvailable = checkAvailable.availableApartments;
      this.arrayAvailable.forEach(function (apartmentID){
        const appartmentKey = ''+apartmentID;
        const priceData = checkAvailable.prices[appartmentKey];
        if (priceData) {
          pricesPerAppartment.set(appartmentKey, priceData);
        }
      });
      
      const currentAppartementId= '' + this.bookID;
      if (pricesPerAppartment.has(currentAppartementId)) {
        const priceDataForSelectedAppartment = pricesPerAppartment.get(currentAppartementId);
        this.apartmentPrice = priceDataForSelectedAppartment.price;
        this.validDate = true;
        console.log("normal price", this.apartmentPrice);
      }
      else {
        this.validDate = false;
        this.dateDeparture = this.dateRange.admDateRange.endDate;
        this.dateArrival = this.dateRange.admDateRange.startDate;
        this.diffDays = Math.floor((this.dateDeparture.getTime() - this.dateArrival.getTime()) / (1000 * 60 * 60 * 24));
        if (this.diffDays<3){
          this.errorMessage = "Selected period has to be minimum 3 nights";
          this.priceCheck = false;
        }
        else{
          this.errorMessage = "Selected dates are not available";
          this.priceCheck = false;
        }
      }
      subscription.unsubscribe();
    this.dateDeparture = this.dateRange.admDateRange.endDate;
    this.dateArrival = this.dateRange.admDateRange.startDate;
    this.diffDays = Math.floor((this.dateDeparture.getTime() - this.dateArrival.getTime()) / (1000 * 60 * 60 * 24));
    console.log("difdays",this.diffDays);
      if (this.diffDays > 29){
        this.extraPriceRaw = (this.extraPriceRaw*0.7)*this.diffDays;
      }
      else if (this.diffDays > 13){
        this.extraPriceRaw = (this.extraPriceRaw *0.8)*this.diffDays;
      }
      else if (this.diffDays > 6){
        this.extraPriceRaw = (this.extraPriceRaw *0.9)*this.diffDays;
      }
      else{
        this.extraPriceRaw = this.extraPriceRaw * this.diffDays;
      }
    console.log("extra", this.extraPriceRaw);
    this.finalPrice = (this.apartmentPrice)*1.05 + this.extraPriceRaw;
    this.finalPrice = Math.round(this.finalPrice);
    if (this.priceCheck){
      this.bookForm.controls['price'].setValue(this.finalPrice);
      }
    });
    console.log("final", this.finalPrice)
  }

async guestArray(){
  this.dataFromDB = this.afDatabase.list('/apartments').valueChanges();
  const itemsDBSub: Subscription = this.dataFromDB.subscribe((apartmentData: ApartmentData[]) =>
    {
      const apartmentMap = apartmentData.map((apartment: ApartmentData) => {
          this.getArrayForID(apartment.id, apartment.maxOccupancy); 
      });
     itemsDBSub.unsubscribe();
    })
}

getArrayForID(id, maxOccupancy:number){
  if (id == this.bookID){
    this.arrayGuests = Array.from(Array(maxOccupancy), (_, i) => i + 1);
    this.strArrayGuests = this.arrayGuests.map(function(e){return e.toString()})
    this.guestOptions = this.amountGuestsControl.valueChanges.pipe(
      startWith(''),
      map(guests => this._filterGuest(guests))
    );
    
  }
  
}

postReservation(){
  const book =  this.bookForm.value;
  const {amountGuestsControl, price, firstName, lastName, phone, country, arrivalControl, departureControl, comment, admDateRange} = book;
  const {startDate, endDate} = admDateRange;
  this.saveToVariable(amountGuestsControl, price, firstName, lastName, phone, country, arrivalControl, departureControl, comment);

    this.bookService.saveBooking(this.bookForm.value);
    this.dateRange = this.dateService.getDateRange();
    let dateArrival = new Date(this.dateRange.admDateRange.startDate);
    let dateDeparture = new Date(this.dateRange.admDateRange.endDate);
    let monthA = new String(dateArrival.getMonth() + 1);
    let monthD = new String(dateDeparture.getMonth() + 1);
    let dayA = new String(dateArrival.getDate());
    let dayD  = new String(dateDeparture.getDate());

    if (dateArrival.getMonth() < 9){monthA = '0' + monthA}
    if (dateDeparture.getMonth() < 9){monthD = '0' + monthD}
    if (dateArrival.getDate() < 10){dayA = '0' + dayA}
    if (dateDeparture.getDate() < 10){dayD = '0' + dayD}

    this.arrivalDateString = dateArrival.getFullYear() + '-' +monthA + '-' + dayA;
    this.departureDateString = dateDeparture.getFullYear() + '-' +monthD + '-' + dayD;
    if (this.bookForm.controls['arrivalControl'].value != "" && this.bookForm.controls['arrivalControl'].value !== null &&
    this.bookForm.controls['departureControl'].value != "" && this.bookForm.controls['departureControl'].value !== null){
      if (this.bookForm.controls['comment'].value != "" && this.bookForm.controls['comment'].value !== null){
        this.urlReservation = this.urlReservation + this.arrivalDateString + '&departureDate=' + this.departureDateString + '&apartmentId=' + this.bookID + '&firstName=' + firstName + '&lastName=' + lastName + '&email=' + this.authService.userEmail + '&phone=' + phone + '&country=' + country + '&price=' + price + '&adults=' + this.amountGuestsNumber + '&notice=' + comment + '&arrivalTime=' + arrivalControl + '&departureTime=' + departureControl;
      }else{
        this.urlReservation = this.urlReservation + this.arrivalDateString + '&departureDate=' + this.departureDateString + '&apartmentId=' + this.bookID + '&firstName=' + firstName + '&lastName=' + lastName + '&email=' + this.authService.userEmail + '&phone=' + phone + '&country=' + country + '&price=' + price + '&adults=' + this.amountGuestsNumber + '&arrivalTime=' + arrivalControl + '&departureTime=' + departureControl;
      }

    }else if(this.bookForm.controls['comment'].value != "" && this.bookForm.controls['comment'].value !== null){
        this.urlReservation = this.urlReservation + this.arrivalDateString + '&departureDate=' + this.departureDateString + '&apartmentId=' + this.bookID + '&firstName=' + firstName + '&lastName=' + lastName + '&email=' + this.authService.userEmail + '&phone=' + phone + '&country=' + country + '&price=' + price + '&adults=' + this.amountGuestsNumber + '&notice=' + comment;
    }else{
        this.urlReservation = this.urlReservation + this.arrivalDateString + '&departureDate=' + this.departureDateString + '&apartmentId=' + this.bookID + '&firstName=' + firstName + '&lastName=' + lastName + '&email=' + this.authService.userEmail + '&phone=' + phone + '&country=' + country + '&price=' + price + '&adults=' + this.amountGuestsNumber;
    }
    this.urlService.saveUrl(this.urlReservation);
    this.router.navigate(['/confirmed']);
  }

  saveToVariable(amountGuests, price, firstName, lastName, phone, country, arrivalTime, departureTime, comment){
    this.amountGuestsNumber = amountGuests;
    this.priceNumber = price;
    this.firstNameString = firstName;
    this.lastNameString = lastName;
    this.phoneString = phone;
    this.countryString = country;
    this.arrivalTimeString = arrivalTime;
    this.departureTimeString = departureTime;
    this.comment = comment;
  }

  showApartment(bookID: number) {
    this.bookForm.reset();
    this.bookIDList = this.afDatabase.list('/apartments', ref => ref.orderByChild('id').equalTo(bookID)).valueChanges();
    const itemsDBSub: Subscription = this.bookIDList.subscribe((apartmentData: ApartmentData[]) =>
    {
      const listWithImageLinks = apartmentData.map((apartment: ApartmentData) => {
        this.bookID = apartment.id;
        this.maxPeople = apartment.maxOccupancy;
        this.guestArray();
        const allImgIds: Observable<any>[] = this.getAllImageLinksForId(apartment.id, apartment.imgcount);
        this.imgList = allImgIds;
        return {...apartment,
         allImgIds: allImgIds}
      });
      
     this.allItemsFromDB = Utils.transformArrayKeyToMap(listWithImageLinks, 'id');
     itemsDBSub.unsubscribe();
    })
    
    }
    
    getAllImageLinksForId(apartmentId: any, imgCount: number): any[] {
      const arrayFromLength = new Array(imgCount);
      const arrayWithRef = arrayFromLength.map((item, index) => this.getRefForIdIndex(apartmentId, (index + 1)));
      const allImageLinks = [];
       for (let i = 0; i < imgCount; i++) {
         const imageLink = this.getRefForIdIndex(apartmentId, i + 1);
         if (Boolean(imageLink)) {
           allImageLinks.push(imageLink);
         }
       }
       return allImageLinks;
     }

  getRefForIdIndex(id: string, index = 1): Observable<any> {
    let downloadUrl;
    try {
     const ref = this.storage.ref(id + '/house' + index + '.jpg');
     downloadUrl = ref.getDownloadURL();
    } catch(e) {
      console.error(e);
    }
   return downloadUrl;
  }

  showApartmentWithId(selectedId: any) {
    
    const allItemsFromDB = this.allItemsFromDB;
    if (allItemsFromDB.has(selectedId)) {
     
     this.selectedItem = allItemsFromDB.get(selectedId);
    } else {
      
    }
   }

   //Form Setup

   setupForm() {
    this.bookForm = this.formBuilder.group({
      admDateRange: this.formBuilder.group({
        startDate: ['', Validators.required],
        endDate: ['', Validators.required]
      }),
      amountGuestsControl:['', Validators.required],
      price:'',
      firstName:['', Validators.required],
      lastName:['', Validators.required],
      phone:['', [Validators.required, Validators.minLength(6)]],
      country:['', [Validators.required, Validators.minLength(2)]],
      arrivalControl:'',
      departureControl:'',
      comment:'',
      policy:''
    })
  }
  
  get f() {
    return this.bookForm.controls;
  }

   //Select options feed

   private _filterArrivalTime(value: string): string[] {
    const filterValue = value.toLowerCase();
  
    return this.optionsArrival.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  
  private _filterDepartureTime(value: string): string[] {
    const filterValue = value.toLowerCase();
  
    return this.optionsDeparture.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }
  
  private _filterGuest(value: string): string[] {
    const filterValue = value.toString().toLowerCase();
  
    return this.strArrayGuests.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  }

