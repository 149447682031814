import { AuthService } from './../shared/services/auth.service';
import { DateService } from './../shared/services/date.service';
import { StateService } from './../shared/services/state.service';
import { ApartmentData } from './../tools/apartmentdata.model';
import { AppartmentDetails } from './../tools/appartment-details.model';
import { Utils } from './../tools/utils';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core'; 
import { Observable, Subscription } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireDatabase } from '@angular/fire/database';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  //Variables
  url = 'https://us-central1-nodesil-91870.cloudfunctions.net/smoobu/apartments'; 
  post: Observable<any>;
  profileUrl: Observable<string | null>;
  fotoForId: Map<string, any> = new Map();
  detailsPerId: Map<string, Observable<AppartmentDetails[]>> = new Map();
  itemsDb: Observable<any>;
  allItemsFromDB: Map<string, any>;
  selectedItem: any;
  arrayApID: number[];
  arrayGuests: number[];
  strArrayGuests: string[];
  amountGuestsControl = new FormControl();
  guestOptions: Observable<string[]>;
  startDate = new FormControl();
  endDate = new FormControl();
  cols = 1;
  responsive = true;
  public innerWidth: any;

  constructor (private authService: AuthService, private router: Router, private dateService: DateService, private formBuilder: FormBuilder, private http: HttpClient, private storage: AngularFireStorage, afDatabase: AngularFireDatabase, private state: StateService){
    this.arrayApID = [1213223,387572,387602,387605,387614,387608,387611,387617,387620,387575,387578,387581,1330904,387587];
    this.itemsDb = afDatabase.list('apartments').valueChanges();
    const itemsDBSub: Subscription = this.itemsDb.subscribe((apartmentData: ApartmentData[]) =>
    {
      const listWithImageLinks = apartmentData.map((apartment: ApartmentData) => {
        const allImgIds: Observable<any>[] = this.getAllImageLinksForId(apartment.id, apartment.imgcount);
        return {...apartment,
         allImgIds: allImgIds}
      });
     this.allItemsFromDB = Utils.transformArrayKeyToMap(listWithImageLinks, 'id');
     itemsDBSub.unsubscribe();
    })
  }

  


  dateForm = this.formBuilder.group({
    admDateRange : this.formBuilder.group({
      startDate: ['', Validators.required],
        endDate: ['', Validators.required]
      }),
    amountGuestsControl:['', Validators.required]
  })

  get f() {
    return this.dateForm.controls;
  }

  checkAvailable(){
    
      this.dateService.saveDate(this.dateForm.value);
      this.router.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/available']);
    }); 
 }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.getAllApartmentsData()
    this.arrayGuests = this.arrayGuests = Array.from(Array(8), (_, i) => i + 1);
    this.strArrayGuests = this.arrayGuests.map(function(e){return e.toString()})
  }

  getAllImageLinksForId(apartmentId: any, imgCount: number): any[] {
    const arrayFromLength = new Array(imgCount);
    const arrayWithRef = arrayFromLength.map((item, index) => this.getRefForIdIndex(apartmentId, (index + 1)));
    const allImageLinks = [];
     for (let i = 0; i < imgCount; i++) {
       const imageLink = this.getRefForIdIndex(apartmentId, i + 1);
       if (Boolean(imageLink)) {
         allImageLinks.push(imageLink);
       }
     }
     return allImageLinks;
   }

   getAllApartmentsData(){
     this.post = this.http.get(this.url);
     const subscription: Subscription = this.post.subscribe((response: {apartments: {id: string, name: string}[]}[]) => {
     const { apartments } = response[0];
     const allIds = apartments.map((a) => a.id);
     for (const id of allIds) {
       // for every id, get first photo
       const firstFotoForThisId = this.getRefForIdIndex(id);
       if (Boolean(firstFotoForThisId)) {
         this.fotoForId.set(id, firstFotoForThisId);
       }
       // for every id, prepare apartment detail request
       const requestDetailsOfId = this.getApartmentDetailedDataForId(id);
       
       this.detailsPerId.set(id, requestDetailsOfId);
     }
     subscription.unsubscribe();
     });
   }

   getRefForIdIndex(id: string, index = 1): Observable<any> {
     let downloadUrl;
     try {
      if (this.innerWidth < 400) 
      {
        const ref = this.storage.ref(id + '/s_house' + index + '.jpg');
        downloadUrl = ref.getDownloadURL();
      } 
      else if (this.innerWidth < 800)
      {
        const ref = this.storage.ref(id + '/m_house' + index + '.jpg');
        downloadUrl = ref.getDownloadURL();
      }
      else 
      {
        const ref = this.storage.ref(id + '/house' + index + '.jpg');
        downloadUrl = ref.getDownloadURL();
      }
      
      
     } catch(e) {
       console.error(e);
     }
    return downloadUrl;
    }

    getApartmentDetailedDataForId(id: string): Observable<any | AppartmentDetails[]> {
     return this.http.get(this.url + '?id=' + id);
   }

   showApartmentWithId(selectedId: any) {
    this.state.apartmentID = selectedId;
    this.state.apartmentIDArray = this.arrayApID;
   }
}
