<mat-sidenav-container class="example-container" autosize style="margin-top: 70px;">
	<mat-sidenav #sidenav class="example-sidenav" mode="side" opened="true" (mouseenter)="mouseenter()" (mouseleave)="mouseleave()" class="mat-elevation-z6">
    <button mat-icon-button (click)="isExpanded = !isExpanded" style="margin-top: 70px;"><mat-icon>menu</mat-icon></button>
    <span class="full-width" *ngIf="isExpanded || isShowing">
      <div *ngFor="let apartment of (sideNavList | async )">
        <div *ngIf="arrayApID.includes(apartment.id)">
          <button mat-button (click)="showApartment(apartment.id)" routerLink="/book">{{ apartment.name}}</button>
        </div>
      </div>
    </span>
	</mat-sidenav>

	<div class="example-sidenav-content">
    <div class="container">
      <mdb-carousel class="carousel slide carousel-fade" [animation]="'fade'">
        <mdb-carousel-item *ngFor="let img of (imgList)">
          <img class="d-block w-100" [src]="img | async">
        </mdb-carousel-item>
      </mdb-carousel>
    
	
    
    <div *ngFor="let bookID of (bookIDList | async)" style="margin-top: 50px;">
      <h1>
        Description
      </h1>
      <p>
        {{ bookID.description}}
      </p>
      <h1>
        Extra information
      </h1>
      <p>
        {{bookID.extraInfo}}
      </p>
      <h1>
        Calendar
      </h1>
      <div [innerHtml]="bookID.calendar | safeHtml"></div>
        <h1>
        Location
      </h1>
      <div style="text-align: center;"> 
        <a [routerLink]="" (click)="openSite(bookID.maps)"><img src="./assets/img/google-maps.png" alt="" ></a>

      </div>
      <h1>
        Amenities
      </h1>
      <div style="text-align: center;">
        <table class="amenities-layout" style="text-align: center;">
          <tr>
            <td>Airco</td>
            <td><span *ngIf="bookID.amenities.airco"><mat-icon>check</mat-icon></span><span *ngIf="!bookID.amenities.airco"><mat-icon>clear</mat-icon></span></td>
            <td>Bedrooms</td>
            <td>{{bookID.amenities.bedrooms}}</td>
          </tr>
          <tr>
            <td>Fireplace</td>
            <td><span *ngIf="bookID.amenities.fireplace"><mat-icon>check</mat-icon></span><span *ngIf="!bookID.amenities.fireplace"><mat-icon>clear</mat-icon></span></td>
            <td>Double Bed</td>
            <td>{{ bookID.amenities.double}}</td>
          </tr>
          <tr>
            <td>Heating</td>
            <td>{{bookID.amenities.heating}}</td>
            <td>Single beds</td>
            <td>{{bookID.amenities.single}}</td>
          </tr>
          <tr>
            <td>Wifi</td>
            <td><span *ngIf="bookID.amenities.wifi"><mat-icon>check</mat-icon></span><span *ngIf="!bookID.amenities.wifi"><mat-icon>clear</mat-icon></span></td>
            <td>Sofa Bed</td>
            <td>{{bookID.amenities.sofa}}</td>
          </tr>
          <tr>
            <td>TV</td>
            <td><span *ngIf="bookID.amenities.tv"><mat-icon>check</mat-icon></span><span *ngIf="!bookID.amenities.tv"><mat-icon>clear</mat-icon></span></td>
            <td>Netflix</td>
            <td><span *ngIf="bookID.amenities.netflix"><mat-icon>check</mat-icon></span><span *ngIf="!bookID.amenities.netflix"><mat-icon>clear</mat-icon></span></td>
          </tr>
          <tr>
            <td>Private Entrance</td>
            <td><span *ngIf="bookID.amenities.private_entrance"><mat-icon>check</mat-icon></span><span *ngIf="!bookID.amenities.private_entrance"><mat-icon>clear</mat-icon></span></td>
            <td>Kitchen</td>
            <td><span *ngIf="bookID.amenities.kitchen"><mat-icon>check</mat-icon></span><span *ngIf="!bookID.amenities.kitchen"><mat-icon>clear</mat-icon></span></td>
          </tr>
          <tr>
            <td>Hairdryer</td>
            <td><span *ngIf="bookID.amenities.hairdryer"><mat-icon>check</mat-icon></span><span *ngIf="!bookID.amenities.hairdryer"><mat-icon>clear</mat-icon></span></td>
            <td>Washing Machine</td>
            <td><span *ngIf="bookID.amenities.washer"><mat-icon>check</mat-icon></span><span *ngIf="!bookID.amenities.washer"><mat-icon>clear</mat-icon></span></td>
          </tr>
          <tr>
            <td>Shampoo</td>
            <td><span *ngIf="bookID.amenities.shampoo"><mat-icon>check</mat-icon></span><span *ngIf="!bookID.amenities.shampoo"><mat-icon>clear</mat-icon></span></td>
            <td>Sheets and Towels</td>
            <td><span *ngIf="bookID.amenities.towelsheet"><mat-icon>check</mat-icon></span><span *ngIf="!bookID.amenities.towelsheet"><mat-icon>clear</mat-icon></span></td>
          </tr>
        </table>
      </div>
    </div>
    
<div style="text-align: center; margin-top: 25px;">
  <span *ngIf="!isLoggedIn" style="font-size: x-large;">Log in to make a reservation!</span>
</div>
    
    <div *ngIf="isLoggedIn">
      <div class="example-container">
        <form [formGroup]="bookForm">
          <mat-grid-list [responsive]="responsive"
          (responsiveColsChange)="cols = $event"
          rowHeight="90"
          gutterSize="6px">
            <mat-grid-tile>
                <mat-form-field class="formfield" appearance="fill">
                  <mat-label>Arrival and Departure Date</mat-label>
                  <mat-date-range-input [rangePicker]="admDateRangePicker" separator="to" formGroupName="admDateRange" >
                    <input matStartDate formControlName="startDate" placeholder="Start date" (click)="admDateRangePicker.open()">
                    <input matEndDate formControlName="endDate"  placeholder="End date" (click)="admDateRangePicker.open()" (dateChange)="updatePrice()">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="admDateRangePicker" ></mat-datepicker-toggle>
                  <mat-date-range-picker #admDateRangePicker></mat-date-range-picker>
                </mat-form-field>
              </mat-grid-tile>
    
              <mat-grid-tile>
                <mat-form-field appearance="fill">
                  <mat-label>Amount of guests</mat-label>
                  <mat-select formControlName="amountGuestsControl" (selectionChange)="updatePrice()">
                    <mat-option *ngFor="let guests of arrayGuests" [value]="guests">
                      {{guests}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="f.amountGuestsControl.errors?.required">Number of guests required</mat-error>
                </mat-form-field>
              </mat-grid-tile> 
    
            <mat-grid-tile>
              <table>
                <tr>
                  <td>
                    <mat-form-field class="formfield" appearance="outline">
                      <mat-label>Total Price</mat-label>
                      <input readonly matInput formControlName="price" placeholder="Price" >
                    </mat-form-field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>{{errorMessage}}</p>
                  </td>
                </tr>
              </table>
            </mat-grid-tile>
    
            <mat-grid-tile>
              <mat-form-field appearance="outline">
                <mat-label>First Name</mat-label>
                <input matInput formControlName="firstName" placeholder="Fill in your first name" required (keyup)="requiredFields()">
                <mat-error *ngIf="f.firstName.errors?.required">First name required</mat-error>
              </mat-form-field>
            </mat-grid-tile>
    
            <mat-grid-tile>
              <mat-form-field appearance="outline">
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="lastName" placeholder="Fill in your last name" required (keyup)="requiredFields()">
                <mat-error *ngIf="f.lastName.errors?.required">Last name required</mat-error>
              </mat-form-field>
            </mat-grid-tile> 
    
            <mat-grid-tile>
              <mat-form-field appearance="outline">
                <mat-label>Phone number</mat-label>
                <input matInput formControlName="phone" placeholder="Fill in your phone number" required (keyup)="requiredFields()">
                <mat-error *ngIf="f.phone.errors?.required">Phone number is required</mat-error>
                <mat-hint>example: +30 69 12 34 56 78</mat-hint>
              </mat-form-field>
            </mat-grid-tile>
    
            <mat-grid-tile>
              <mat-form-field appearance="outline">
                <mat-label>Country</mat-label>
                <input matInput formControlName="country" placeholder="Fill in your country" required (keyup)="requiredFields()">
                <mat-error *ngIf="f.country.errors?.required">Country is required</mat-error>
                <mat-hint>example: Greece</mat-hint>
              </mat-form-field>
            </mat-grid-tile>
    
            <mat-grid-tile>
              <mat-form-field appearance="fill">
                <mat-label>Pick Arrival Time</mat-label>
                <mat-select formControlName="arrivalControl">
                  <mat-option *ngFor="let arrival of arrivalOptions | async" [value]="arrival">
                    {{arrival}}
                  </mat-option>
                </mat-select>
                <mat-hint>Check in times are from 14:00 until 2:00</mat-hint>
              </mat-form-field>
            </mat-grid-tile>
    
            <mat-grid-tile>
              <mat-form-field appearance="fill">
                <mat-label>Pick Departure Time</mat-label>
                <mat-select formControlName="departureControl">
                  <mat-option *ngFor="let departure of departureOptions | async" [value]="departure">
                    {{departure}}
                  </mat-option>
                </mat-select>
                <mat-hint>Check out is before 12:00</mat-hint>
              </mat-form-field>
            </mat-grid-tile>
    
              <mat-grid-tile>
                <mat-form-field appearance="fill" (responsiveColsChange)="colspan = $event">
                  <mat-label>Extra comment</mat-label>
                  <textarea matInput formControlName="comment"></textarea>
                  <mat-hint>Hint: We are coming with our dog</mat-hint>
                </mat-form-field>
              </mat-grid-tile>
    
              <mat-grid-tile>
                <mat-checkbox class="example-margin" formControlName="policy" [disabled]="!policyCheckbox || !priceCheck">Agree to terms and conditions</mat-checkbox>
                
              </mat-grid-tile>
    
              <mat-grid-tile>
                <button mat-raised-button (click)="postReservation()" [disabled]="!bookForm.controls.policy.value || !policyCheckbox || !priceCheck">Book</button>
              </mat-grid-tile>
    
            </mat-grid-list>
          </form> 
        </div>
      </div>
      <div *ngFor="let bookID of (bookIDList | async)" style="margin-top: 50px;">
        License AMA: {{bookID.amenities.ama}}
      </div>
	</div>
</div>
</mat-sidenav-container>





