import { MailsendComponent } from './../mailsend/mailsend.component';
import { Error } from './../tools/error';
import { StateService } from './../shared/services/state.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog} from '@angular/material/dialog';
import { AuthService } from '../shared/services/auth.service';
import { BehaviorSubject } from 'rxjs';
import { FormBuilder, FormGroup, Validators, AbstractControl} from "@angular/forms";
import { ComparePassword } from "../tools/validators/custom.validator";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  errorState: boolean;
  error:Error;
  errorMessage: string;

  constructor(
    public dialogRef: MatDialogRef<RegisterComponent>,
    public authService: AuthService,
    private stateService: StateService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    ) {}
    

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group(
      {
        email: ["", [Validators.required, Validators.email]],
        password: ["", [Validators.required, Validators.minLength(6)]],
        confirmPassword: [""]
      },
      {
        // Used custom form validator name
        validator: ComparePassword("password", "confirmPassword")
      }
    );
  }
  // Getter function in order to get form controls value
  get f() {
    return this.registerForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // Returns false if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    else{
      this.register();
    }

    console.log("Form Values" + JSON.stringify(this.registerForm.value));
  }
  
  async register() {
    await this.authService.register(this.registerForm.controls['email'].value, this.registerForm.controls['password'].value);
    console.log('authservice error', this.authService.error);
    console.log('authstate',this.authService.errorState);
    if (this.authService.errorState){
      this.error = this.authService.error;
      this.errorMessage = this.error.message;
      this.errorState = true;
     }
     else{
       this.errorState = false;
       this.errorMessage = '';
       this.dialogRef.close();
       const dialogRef = this.dialog.open(MailsendComponent, {
        width: '300px',
        data: {}
      });
     }
 }

}
