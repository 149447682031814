import { RegisterComponent } from './../register/register.component';
import { Error } from './../tools/error';
import { StateService } from './../shared/services/state.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog} from '@angular/material/dialog';
import { AuthService } from '../shared/services/auth.service';
import { BehaviorSubject, empty } from 'rxjs';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { emailVerified } from "../tools/validators/custom.validator";
import { Router } from '@angular/router';



@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  modalForm: FormGroup;
  emailVerified: boolean;
  error: Error;
  errorMessage: string = '';
  hasError: boolean;
  mailSend: boolean;
  noMail: boolean;
  

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ModalComponent>,
    public authService: AuthService,
    private stateService: StateService,
    private router: Router) {}
    

  onNoClick(): void {
    this.dialogRef.close();
  }

  register(): void {
    this.dialogRef.close();
    const dialogRef = this.dialog.open(RegisterComponent, {
      width: '300px',
      data: {}
    });
  }

  ngOnInit() {
    this.modalForm = this.fb.group({
      email:['', [Validators.required, Validators.email]],
      password:['', Validators.required]
    })
  }

  async login() {
    await this.authService.login(this.modalForm.controls['email'].value, this.modalForm.controls['password'].value);
     if (this.stateService.loggedIn){
       this.dialogRef.close();
       this.router.navigate(['/home']);
     }
     else{
       if (this.authService.errorState !== undefined){
        this.error = this.authService.error;
        this.errorMessage = this.error.message;
       }
       else{
         this.errorMessage = '';
       }
       
       if (this.authService.emailVerified){
       }
       else{
        if (this.errorMessage !== ''){
          this.hasError = true;
       }else{
         this.emailVerified = false;
       }

       }
     }
     
  }

  async  loginWithGoogle(){
    this.dialogRef.close();
    await  this.authService.loginWithGoogle()
    this.router.navigate(['/home']);
  }

  async  loginWithFacebook(){
    this.dialogRef.close();
    await  this.authService.loginWithFacebook()
    this.router.navigate(['/home']);
  }

  async  loginWithYahoo(){
    this.dialogRef.close();
    await  this.authService.loginWithYahoo()
    this.router.navigate(['/home']);
  }

  get email() { return this.modalForm.get('email'); }

  get password() { return this.modalForm.get('password'); }

  resetVariables(){
    this.emailVerified = true;
    this.hasError = false;
    this.errorMessage = '';
    console.log('reset variables')
  }

  async forgotPassword(){
    console.log('email', this.modalForm.controls['email'].value);
    if (this.modalForm.controls['email'].value !== ''){
      await this.authService.sendPasswordResetEmail(this.modalForm.controls['email'].value);
      if (this.authService.errorState){
        this.hasError = true;
      }
      else{
        this.mailSend = true;
        this.router.navigate(['/home']);
      }
     
    }
    else{
      this.noMail = true;
    }
  }
}