import { SafeHtmlPipe } from './tools/sanitizer.pipe';
import { AuthService } from './shared/services/auth.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl} from '@angular/platform-browser';
import { HttpClientModule} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


//Addons
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '../environments/environment';

//Materials
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule} from '@angular/material/button';
import { MatInputModule} from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatGridListResponsiveModule } from './tools/gridlist.module';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule} from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule} from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav'; 
import { MatTabsModule } from '@angular/material/tabs';

//MDBBootstrap
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CarouselModule } from 'angular-bootstrap-md';


//Components
import { InfoComponent } from './info/info.component';
import { PolicyComponent } from './policy/policy.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { PropertiesComponent } from './properties/properties.component';
import { AvailableComponent } from './available/available.component';
import { BookComponent } from './book/book.component';
import { ModalComponent } from './modal/modal.component';
import { RegisterComponent } from './register/register.component';
import { ConfirmedComponent } from './confirmed/confirmed.component';
import { MailsendComponent } from './mailsend/mailsend.component';


@NgModule({
  declarations: [
    AppComponent,
    InfoComponent,
    PolicyComponent,
    ContactComponent,
    HomeComponent,
    PropertiesComponent,
    AvailableComponent,
    BookComponent,
    ModalComponent,
    RegisterComponent,
    ConfirmedComponent,
    MailsendComponent,
    SafeHtmlPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    MatToolbarModule,
    MatIconModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatRippleModule,
    MatCardModule,
    MDBBootstrapModule.forRoot(),
    CarouselModule,
    MatDialogModule, 
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatGridListResponsiveModule,
    MatGridListModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatSidenavModule,
    MatTabsModule
    

  ],
  providers: [AuthService, SafeHtmlPipe],
  bootstrap: [AppComponent],
  entryComponents: [ModalComponent, RegisterComponent]
})
export class AppModule { }
