import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase  } from '@angular/fire/database';
import  { Observable } from  'rxjs';
import  { FormGroup, FormBuilder, Validators } from  '@angular/forms'; 

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  itemName = '';
      itemEmail = '';
      itemSubject = '';
      itemMessage = '';
      items: Observable<any[]>;
      contactForm;

      ngOnInit(): void {
      }

  constructor(private fb: FormBuilder, private db: AngularFireDatabase) {
    this.items = db.list('messages').valueChanges()
// Passing in MD_Bootstrap form validation 
    this.contactForm = fb.group({
    contactFormName: ['', Validators.required],
    contactFormEmail: ['', [Validators.required, Validators.email]],
    contactFormSubject: ['', Validators.required],
    contactFormMessage: ['', Validators.required]
 });
   }
// Pushing the contact-form to the firebase data base
   onSubmit()  {
   this.db.list('/messages').push({ name: this.itemName, email: this.itemEmail, subject: this.itemSubject, 
   message: this.itemMessage});
//Popup message
   alert('Thank you for contacting us, your message has gone through!')
  }


// Clearing the form after submit
clearForm() {
     this.contactForm.reset();
    }
    
    get f() {
      return this.contactForm.controls;
    }
  
  }
