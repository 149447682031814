import { Error } from './../../tools/error';
import { StateService } from './state.service';
import { Router, provideRoutes } from '@angular/router';
import { auth } from 'firebase/app';
import { User } from 'firebase';
import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: User;
  loggedIn: boolean;
  emailVerified: boolean;
  userEmail: string;
  error: Error;
  errorState: boolean;
  errorMessage: string;
  

  constructor(public afAuth: AngularFireAuth, public router: Router, public stateService: StateService) {
    this.afAuth.authState.subscribe(user => {
      if (user){
        this.user = user;
        if (user.emailVerified) {
          this.stateService.setLoggedIn(true);
          this.userEmail = user.email;
        }
        localStorage.setItem('user', JSON.stringify(this.user));
      } else {
        localStorage.setItem('user', null);
        this.stateService.setLoggedIn(false);
      }
    })
   }

   async login(email: string, password: string) {
     let result;
     try {
       result = await this.afAuth.signInWithEmailAndPassword(email, password); 
       this.emailVerified = (await this.afAuth.currentUser).emailVerified;
      if (this.emailVerified) {
        this.stateService.setLoggedIn(true);
        return result;
      } else {
        this.logout();
      }

     } catch (e) {
       this.error = e;
       this.errorState = true;
       this.errorMessage = this.error.message;
       this.stateService.setLoggedIn(false);
     }

}

async register(email: string, password: string) {
  this.errorState = false;
  let result;
     try {
       result = await this.afAuth.createUserWithEmailAndPassword(email, password); 
       this.sendEmailVerification();
       this.logout();
      } catch (e) {
       this.error = e;
       this.errorState = true;
       this.errorMessage = this.error.message;
      }
}

async sendEmailVerification() {
  
  await (await this.afAuth.currentUser).sendEmailVerification()
}

async sendPasswordResetEmail(passwordResetEmail: string) { 
  this.errorState = false;
  let result;
     try {
       result = await this.afAuth.sendPasswordResetEmail(passwordResetEmail);
      } catch (e) {
       this.error = e;
       this.errorState = true;
       this.errorMessage = this.error.message;
      }
}

async logout(){
  await this.afAuth.signOut();
  localStorage.removeItem('user');
  this.stateService.setLoggedIn(false);
}

get isLoggedIn(): boolean {
  const  user  =  JSON.parse(localStorage.getItem('user'));
  return  user  !==  null;
}

async  loginWithGoogle(){
    await  this.afAuth.signInWithPopup(new auth.GoogleAuthProvider())
    this.stateService.setLoggedIn(true);
}

async  loginWithFacebook(){
  await  this.afAuth.signInWithPopup(new auth.FacebookAuthProvider())
  this.stateService.setLoggedIn(true);
}

async  loginWithYahoo(){
  await  this.afAuth.signInWithPopup(new auth.OAuthProvider('yahoo.com'))

  this.stateService.setLoggedIn(true);
}

}
