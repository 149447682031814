import { DateRange } from './../../tools/daterange';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  dateRangeService: DateRange;

  constructor() { }


  saveDate(date){
    this.dateRangeService = date;
  }

  getDateRange(): DateRange {
    return (this.dateRangeService);
}
}
