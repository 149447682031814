<div style="height: 100vh;" class="intro">
  <div class="background">
    <form [formGroup]="dateForm" (ngSubmit)="checkAvailable()">
      <mat-form-field appearance="outline">
          <mat-label>Pick a date</mat-label>
          <mat-date-range-input [rangePicker]="admDateRangePicker" formGroupName="admDateRange" color="primary" >
            <input matStartDate formControlName="startDate" placeholder="Start date" (click)="admDateRangePicker.open()">
            <input matEndDate formControlName="endDate" placeholder="End date" (click)="admDateRangePicker.open()">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="admDateRangePicker"></mat-datepicker-toggle>
          <mat-date-range-picker #admDateRangePicker></mat-date-range-picker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label><mat-icon>people</mat-icon></mat-label>
        <mat-select formControlName="amountGuestsControl">
          <mat-option *ngFor="let guests of strArrayGuests" [value]="guests">
            {{guests}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <span>
        <button mat-icon-button>
          <mat-icon [inline]="true" >search</mat-icon>
        </button>
      </span>
    </form>
  </div>
</div>



<div class="whitespace">
  <div class="container">
    <span>Book In Leonidio offers holiday houses and apartments for short term vacation. 
      We offer the lowest rates with a free cancellation policy. 
      Most accommodations are in the center of Leonidio or just outside. 
      To check what is available and the prices. Fill in the date of arrival and departure above and the number of guests.
      To make a reservation, you have to log in.</span>

      <mat-grid-list cols="3" rowHeight="350px">
        <mat-grid-tile>
          <div style="text-align: center;margin-top: 50px;">
            <button mat-fab class="example-icon" routerLink="/policy" >
              <mat-icon [inline]="true" style="font-size: x-large;">policy</mat-icon>
            </button>
            <h1>Policy</h1><p>Please take your time to read the terms and conditions.</p>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div style="text-align: center;margin-top: 50px;">
            <button mat-fab class="example-icon" routerLink="/contact" >
              <mat-icon [inline]="true" style="font-size: x-large;">email</mat-icon>
            </button>
            <h1>Contact</h1><p>Use the contact form to leave a message and we will get back to you as soon as possible.</p>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div style="text-align: center;margin-top: 50px;">
            <button mat-fab class="example-icon" routerLink="/info" >
              <mat-icon [inline]="true" style="font-size: x-large;">info</mat-icon>
            </button>
            <h1>Info</h1><p>Get to know what this website is all about!</p>
          </div>
        </mat-grid-tile>
      </mat-grid-list>

      
  </div>
  <!--<div class="second">
    <mat-grid-list [responsive]="responsive" (responsiveColsChange)="cols = $event" rowHeight="300px" gutterSize="6px">
      <mat-grid-tile> 
        <mat-card class="mat-elevation-z5" >
        <mat-card-header class="header1">
          
          <mat-card-title>Save money</mat-card-title>
         
          <mat-card-subtitle>Best price for all properties</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            
                    <ul>
                        <li>Save 10% compared to Airbnb or Booking.com</li>
                        <li>Discount of 10% for 1 week, 30% for a month</li>
                        <li>Lower rates in low season</li>
                    </ul>        
              
        </mat-card-content>
    </mat-card></mat-grid-tile>
    <mat-grid-tile>
      <mat-card class="mat-elevation-z5">
          <mat-card-header class="header2">
            
            <mat-card-title>Easy reservation</mat-card-title>
            <mat-card-subtitle>Instant and straight forward</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
              
                      <ul>
                          <li>No prepayment, we place our trust in you.</li>
                          <li>Free cancellation, just contact us so we know!</li>
                          <li>No bank transfer, just cash upon arrival.</li>
                      </ul>        
                  
          </mat-card-content>
      </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-card class="mat-elevation-z5">
          <mat-card-header class="header3">
            
            <mat-card-title>Need help!</mat-card-title>
            <mat-card-subtitle>We are happy to share our knowledge</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
              
                      <ul>
                          <li>Best restaurants for dinner or bars for a night out</li>
                          <li>Grocery stores, fruit and vegetable market</li>
                          <li>Climbing sectors, beaches and places to visit!</li>
                      </ul>        
                 
          </mat-card-content>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
  </div>-->
  <div class="properties">
   <!-- <h1>listings in and around leonidio</h1>
    <h2>To check the available houses or apartments, pick a date and select the amount of guests at the top</h2>-->
    <mat-grid-list [responsive]="responsive" (responsiveColsChange)="cols = $event" rowHeight="360px">
      <mat-grid-tile *ngFor="let apartment of itemsDb | async" [rowspan]="1" [colspan]="1">
          <img width="100%" height="100%" *ngIf="fotoForId.has(apartment.id)" [src]="(fotoForId.get(apartment.id)) | async" alt="model {{apartment.name}}">
          <mat-grid-tile-footer>
            
            <h3 mat-line>{{apartment.name}}</h3>
            <span mat-line *ngIf="((detailsPerId.get(apartment.id) | async)) as detailsList">
              <ng-container *ngIf="detailsList[0] as firstDetail" >
                <h3>
                  {{ firstDetail.rooms.maxOccupancy }} <mat-icon>accessibility</mat-icon> 
                </h3>
                  
                  <!--{{ apartment.averagePrice }} <span style="font-size: 1vw; vertical-align: top;">*</span><mat-icon>euro</mat-icon>-->
                
                
              </ng-container>
            </span>
            <button mat-button (click)="showApartmentWithId(apartment.id)" routerLink="/book">
                <mat-icon>visibility</mat-icon>
            </button>
        </mat-grid-tile-footer>
      </mat-grid-tile>
    </mat-grid-list>
    </div>
 