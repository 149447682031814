
    <div class="container" style="text-align: center;" style="padding-top: 100px;">
        <form [formGroup]="contactForm" class="example-form">
            <mat-form-field class="example-full-width">
              
                <mat-label style="padding-left: 30px;">Your name</mat-label>
                <mat-icon>account_circle</mat-icon>
                <input matInput placeholder="Your name" formControlName="contactFormName" [(ngModel)]="itemName">
                <mat-error *ngIf="f.contactFormName.errors?.required">Name is required</mat-error>
              </mat-form-field>
<br>
              <mat-form-field class="example-full-width">
              
                <mat-label style="padding-left: 30px;">Your email</mat-label>
                <mat-icon>alternate_email</mat-icon>
                <input matInput placeholder="Your email" formControlName="contactFormEmail" [(ngModel)]="itemEmail">
                <mat-error *ngIf="f.contactFormEmail.errors?.required">Name is required</mat-error>
                <mat-error *ngIf="f.contactFormEmail.errors?.email">Email is wrong format</mat-error>
              </mat-form-field>
<br>
              <mat-form-field class="example-full-width">
              
                <mat-label style="padding-left: 30px;">Subject</mat-label>
                <mat-icon>title</mat-icon>
                <input matInput placeholder="Subject" formControlName="contactFormSubject" [(ngModel)]="itemSubject">
                <mat-error *ngIf="f.contactFormSubject.errors?.required">Subject is required</mat-error>
              </mat-form-field>
<br>
              <mat-form-field class="example-full-width">
              
                <mat-label style="padding-left: 30px;">Your message</mat-label>
                <mat-icon>message</mat-icon>
                <input matInput placeholder="Your message" formControlName="contactFormMessage" [(ngModel)]="itemMessage">
                <mat-error *ngIf="f.contactFormMessage.errors?.required">Message is required</mat-error>
              </mat-form-field>
    
    
        <div class="text-center">
        <button [disabled]="contactForm.invalid" (click)="onSubmit()" (click)="clearForm()" type="submit" class="btn waves-light" mdbWavesEffect>Send
        <i class="fa fa-paper-plane-o ml-1"></i>
        </button>
     
        
    </div>
    