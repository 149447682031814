import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  apartmentID: number;
  apartmentIDArray: number[];
  loginData$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  loginData: any;
  loggedIn: boolean;
  
  constructor() { }

  setLoginData(data): void {
    console.log('setting loggin data')
    this.loginData = data;
    this.loginData$.next(data);
  }

  setLoggedIn(isLoggedIn: boolean): void {
    this.loggedIn = isLoggedIn;
  }
}
