import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {

  expanded: boolean;
  isShowing: boolean;

  constructor() { }

  setSideNav(expanded: boolean): void {
    this.expanded = expanded;
  }

  get isExpanded(): boolean {
    return  this.expanded;
  }

  get mouseenter(): boolean {
    if (!this.isExpanded) {
      this.isShowing = true;
      return this.isShowing;
    }
  }

  get mouseleave(): boolean {
    if (!this.isExpanded) {
      this.isShowing = false;
      return this.isShowing;
    }
  }
}
