import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  url: string;

  constructor() { }


  saveUrl(url){
    this.url = url;
  }

  getUrl(): string {
    return (this.url);
  }
}