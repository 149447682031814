export class Utils {
    /**
     * transforms array of values to Map object,
     * using given property as group identifier to list all values with that property
     * the value will be the entire element
     * will not list items that are missing this property
     * @param array
     * @param property
     * @returns {Map<K, V>}
     */
    static transformArrayPropertyToListMap(array: any[], property: string): Map<any, any> {
        const map = new Map<any, any>();
        array.reduce((tempMap: Map<string, any>, element) => {
            const elementKey = element[property];
            if (elementKey) {
                let newList = [element];
                if (tempMap.has(elementKey)) {
                    const listSoFar = tempMap.get(elementKey);
                    newList = listSoFar.concat(element);
                }
                return tempMap.set(elementKey, newList);
            } else {
                console.log('transformArrayKeyToMap', property, 'is missing for', element);
            }
        }, map);
        return map;
    }

     /**
     * transforms array to Map object, using 'key' as the key identifier,
     * only if that key is present on the value
     * the value will be the entire element
     * @param array
     * @param key
     * @returns {Map<K, V>}
     */
    static transformArrayKeyToMap(array: any[], key: string): Map<any, any> {
        const map = new Map<any, any>();
        array.reduce((tempMap: Map<string, any>, element) => {
            const elementKey = element[key];
            if (!elementKey) {
                return tempMap.set(element['id'], element);
            } else {
                return tempMap.set(element[key], element);
            }
        }, map);
        return map;
    }
}