import { AuthService } from './../../shared/services/auth.service';
import { FormGroup, AbstractControl } from "@angular/forms";

// To validate password and confirm password
export function ComparePassword(
  controlName: string,
  matchingControlName: string
) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      return;
    }

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

// To validate first name
export function ValidateFirstName(control: AbstractControl) {
  if (!control.value.startsWith("@")) {
    return { validFname: true };
  }
  return null;
}

// To validate last name
export function ValidateLastName(control: AbstractControl) {
  if (control.value.length <= 3) {
    return { validLname: true };
  }
  return null;
}

// To validate first name
export function emailVerified(control: AbstractControl) {
  
  if (this.authService.emailVerified.value == false) {
    return { verifiedEmail: true };
  }
  return null;
}