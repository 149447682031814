import { BookingIDService } from './../shared/services/booking-id.service';
import { Reservation } from './../tools/reservation';
import { Observable, Subscription  } from 'rxjs';
import { UrlService } from './../shared/services/url.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-confirmed',
  templateUrl: './confirmed.component.html',
  styleUrls: ['./confirmed.component.scss']
})
export class ConfirmedComponent implements OnInit {

  urlBooking: string;
  urlReservationDetails = 'https://us-central1-nodesil-91870.cloudfunctions.net/reservationDetails?reservationId='; 
  reservationID: number;
  reservation: Reservation;

  constructor(private urlService:UrlService, private http:HttpClient, private bookingIDService:BookingIDService) { }

  async ngOnInit() {
    this.urlBooking = this.urlService.getUrl();
    await this.http.get(this.urlBooking).toPromise().then((reservation: Reservation) => { 
           this.reservationID = reservation.id;
           this.getReservationDetails();
         });
  }

  async getReservationDetails(){
    const subscription: Subscription = this.http.get(this.urlReservationDetails + this.reservationID).subscribe((reservation: Reservation) => {
      this.reservation = reservation;
    });
  }

}
