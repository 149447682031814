<mat-sidenav-container class="example-container" autosize  style="margin-top: 70px;">
	<mat-sidenav #sidenav class="example-sidenav" mode="side" opened="true" (mouseenter)="mouseenter()" (mouseleave)="mouseleave()" class="mat-elevation-z6">
    <button mat-icon-button (click)="isExpanded = !isExpanded"><mat-icon>menu</mat-icon></button>
    <span class="full-width" *ngIf="isExpanded || isShowing">
      <div *ngFor=" let id of (post | async)">
        <div *ngFor="let apartment of id.apartments">
            <div *ngIf="arrayAvailable.includes(apartment.id)">
                    <button mat-button (click)="showApartmentWithId(apartment.id)" routerLink="/book">{{apartment.name}}</button>   
            </div>
        </div>
      </div>
    </span>
  
  </mat-sidenav>
  
 

  <div class="example-sidenav-content">
    <div *ngIf="noHouses">
      <p>There are no houses available for the selected dates. Make sure you selected at least 3 nights as this is the minimum required.</p>
    </div>
    <div *ngIf="!noHouses">
      <mat-grid-list [responsive]="responsive" (responsiveColsChange)="cols = $event" rowHeight="360px" *ngFor="let response of (post | async)">
        <ng-container *ngFor="let apartment of response.apartments">
          <mat-grid-tile *ngIf="arrayAvailable.includes(apartment.id)">
            <img width="100%" height="100%" [src]="(fotoForId.get(apartment.id)) | async" alt="model {{apartment.name}}">
            <mat-grid-tile-footer>
              <h3 class="price" mat-line style="align-self: center;">{{apartment.name}}</h3>
              <span mat-line *ngIf="apartmentsDataPerId.get(apartment.id+'') as data">
                <ng-container *ngIf="data">
                  <div class="price">
                    <!-- {{ data.maxOccupancy }} <mat-icon>accessibility</mat-icon> -->
                    {{ finalPricesPerAppartment.get(apartment.id) }} €
                    ( {{ finalPricesPerDay.get(apartment.id)}} € /d)
                  </div>
                  
                </ng-container>
              </span>
              <button mat-button (click)="showApartmentWithId(apartment.id)" routerLink="/book">
                  <mat-icon>visibility</mat-icon>
              </button>
          </mat-grid-tile-footer>
        </mat-grid-tile>
        </ng-container>
      </mat-grid-list>
    </div>
  </div>
 
</mat-sidenav-container>

