import { HttpClient } from '@angular/common/http';
import { Reservation } from './../../tools/reservation';
import { Subscription, Observable } from 'rxjs';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class BookingIDService {

  constructor(private http:HttpClient) { }

  getBookingID(urlBooking:string):any{
    const subscription: Subscription = this.http.get(urlBooking).subscribe((reservation: Reservation) => {
      return reservation
    });
  }


}
