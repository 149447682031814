import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BookService {

  dateRangeService: Observable<any>;

  constructor() { }

  saveBooking(booking){
    this.dateRangeService = booking;
    console.log(booking)
  }

}
