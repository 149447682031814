import { SidenavService } from './shared/services/sidenav.service';
import { Title, Meta } from '@angular/platform-browser';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from './shared/services/auth.service';
import { StateService } from './shared/services/state.service';
import { Component, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalComponent } from './modal/modal.component';
import { User } from 'firebase';
import { DateService } from './shared/services/date.service';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})



export class AppComponent {
  title = 'Book In Leonidio';
  email: string;
  loginData: any;
  loginData$: any;
  isLoggedIn: boolean;
  user: User;
  isExpanded: boolean;
  routeLinks: any[];
  activeLinkIndex = -1;
  arrayGuests: number[];
  strArrayGuests: string[];

  
  constructor(private titleService: Title, private metaService: Meta, public sideNavService:SidenavService, public router: Router, public formBuilder: FormBuilder, public dateService :DateService, public afAuth: AngularFireAuth, public authService: AuthService, public dialog: MatDialog, public stateService: StateService) {
    this.isLoggedIn = this.stateService.loggedIn;
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
        /* gtag('config', 'UA-181852855-1');*/
          gtag('config', 'UA-181852855-1', 
                {
                  'page_path': event.urlAfterRedirects
                }
               );
       }
    }
    )}

  clickHome(){this.router.navigate(['/home']);}
  clickInfo(){this.router.navigate(['/info']);}
  clickPolicy(){this.router.navigate(['/policy']);}
  clickContact(){this.router.navigate(['/contact']);}

  openDialog(): void {
    const dialogRef = this.dialog.open(ModalComponent, {
      width: '300px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.email = result;
    });
  }

 

  dateForm = this.formBuilder.group({
    admDateRange : this.formBuilder.group({
      startDate: ['', Validators.required],
        endDate: ['', Validators.required]
      }),
    amountGuestsControl:['', Validators.required]
  })

  get f() {
    return this.dateForm.controls;
  }

  checkAvailable(){
    
      this.dateService.saveDate(this.dateForm.value);
      this.router.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/available']);
    }); 
 }

  ngOnInit(){
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'keywords', content: 'reservation, booking, leonidio, climbing, house, apartment, rent, cheap, lowest rate, book in leonidio, rental, accomodation, free cancellation, short term rental, vacation, holiday home'},
      {name: 'description', content: 'Book In Leonidio offers holiday houses and apartments for short term vacation. We offer the lowest rates with a free cancellation policy. Most accommodations are in the center of Leonidio or just outside. Make your reservation now!'},
      {name: 'robots', content: 'index, follow'}
    ]);

    this.afAuth.authState.subscribe(user => {
      if (user){
        this.stateService.setLoggedIn(true);
      } else {
        this.stateService.setLoggedIn(false);
      }
      this.isLoggedIn = this.stateService.loggedIn;
    })
    this.router.events.subscribe((res) => {
      this.activeLinkIndex = this.routeLinks.indexOf(this.routeLinks.find(tab => tab.link === '.' + this.router.url));
  });
  this.arrayGuests = this.arrayGuests = Array.from(Array(8), (_, i) => i + 1);
    this.strArrayGuests = this.arrayGuests.map(function(e){return e.toString()})
  }

  getActiveClass(indexOfRouteLink) {
    let tabsclass = 'mat-tab-link';
    if (this.activeLinkIndex === indexOfRouteLink) {
      tabsclass = 'mat-tab-link mat-tab-label-active';
    }

    return tabsclass;
  }

  logout(){
    this.authService.logout();
    this.stateService.setLoggedIn(false);
    this.router.navigate(['/home']);
  }


}
