<mat-toolbar color="primary" class="mat-elevation-z10">


  <div class="big">
    <button mat-icon-button class="example-icon" routerLink="/home">
      home
    </button>
    <button mat-icon-button class="example-icon" routerLink="/info">
      info
    </button>
    <button mat-icon-button class="example-icon" routerLink="/policy">
      policy
    </button>
    <button mat-icon-button class="example-icon" routerLink="/contact">
      contact
    </button>
  </div>
  
  <span class="example-spacer"></span>

  
  <div *ngIf="!isLoggedIn" flex>
    <button mat-raised-button (click)="openDialog()" style="margin-right: 15px;">log in</button>
    
  </div>
  
  <div *ngIf="isLoggedIn" class="fill-space">
    <button mat-raised-button (click)="logout()">log out</button>
  </div>
  
</mat-toolbar>
<router-outlet></router-outlet>