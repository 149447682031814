import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PolicyComponent } from './policy/policy.component';
import { InfoComponent } from './info/info.component';
import { AvailableComponent } from './available/available.component';
import { PropertiesComponent } from './properties/properties.component';
import { ContactComponent } from './contact/contact.component';
import { BookComponent } from './book/book.component';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './register/register.component';
import { ConfirmedComponent } from './confirmed/confirmed.component';

const routes: Routes = [
  {path: "", pathMatch: "full", redirectTo: "/home"},
  {path: "home", component: HomeComponent},
  {path: "policy", component: PolicyComponent},
  {path: "info", component: InfoComponent},
  {path: "available", component: AvailableComponent},
  {path: "book", component: BookComponent},
  {path: "contact", component: ContactComponent},
  {path: "properties", component: PropertiesComponent},
  {path: "register", component: RegisterComponent},
  {path: "confirmed", component: ConfirmedComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
