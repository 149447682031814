<div class="title">


<h1>Info</h1>

<mat-grid-list [responsive]="responsive" (responsiveColsChange)="cols = $event" rowHeight="300px" gutterSize="6px">
    <mat-grid-tile><mat-card class="mat-elevation-z5">
        <mat-card-header>
          <mat-card-title>Save money</mat-card-title><mat-icon style="font-size: 36px" [inline]="true">euro</mat-icon>
          <mat-card-subtitle>Best price for all properties</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <div class="flex-row">
                <div class="temp">
                    <ul>
                        <li>Save 10% compared to Airbnb or Booking.com</li>
                    </ul>        
                </div>
          </div>
        </mat-card-content>
    </mat-card></mat-grid-tile>
    <mat-grid-tile>
        <mat-card class="mat-elevation-z5">
            <mat-card-header>
              <mat-card-title>Easy booking</mat-card-title><mat-icon style="font-size: 36px" [inline]="true">booking</mat-icon>
              <mat-card-subtitle>Fast and straight forward</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="flex-row">
                    <div class="temp">
                        <ul>
                            <li>No prepayment, we place our trust in you.</li>
                            <li>Free cancellation, just contact us so we know!</li>
                            <li>No bank transfer, just cash upon arrival.</li>
                        </ul>        
                    </div>
              </div>
            </mat-card-content>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-card class="mat-elevation-z5">
        <mat-card-header>
          <mat-card-title>Discount</mat-card-title><mat-icon style="font-size: 36px" [inline]="true">gesture</mat-icon>
          <mat-card-subtitle>Benefit from price reduction</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <div class="flex-row">
                <div class="temp">
                    <ul>
                        <li>For stays of 7 days or more you get 10%</li>
                        <li>For stays of 14 days or more you get 20%</li>
                        <li>30 days or more = 30%</li>
                    </ul>        
                </div>
          </div>
        </mat-card-content>
    </mat-card>
</mat-grid-tile>
    <mat-grid-tile>
        <mat-card class="mat-elevation-z5">
        <mat-card-header>
          <mat-card-title>Communication</mat-card-title><mat-icon style="font-size: 36px" [inline]="true">call</mat-icon>
          <mat-card-subtitle>Get in touch directly</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <div class="flex-row">
                <div class="temp">
                    <ul>
                        <li>Get email and number directly upon booking</li>
                        <li>Use the contact form for any questions</li>
                    </ul>        
                </div>
          </div>
        </mat-card-content>
    </mat-card></mat-grid-tile>
    <mat-grid-tile>
        <mat-card class="mat-elevation-z5">
            <mat-card-header>
              <mat-card-title>Guide</mat-card-title><mat-icon style="font-size: 36px" [inline]="true">help</mat-icon>
              <mat-card-subtitle>Learn about the area</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="flex-row">
                    <div class="temp">
                        <ul>
                            <li>Where can I eat and drink what?</li>
                            <li>Where to go for groceries?</li>
                            <li>Climbing sectors, beaches and places to visist!</li>
                        </ul>        
                    </div>
              </div>
            </mat-card-content>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-card class="mat-elevation-z5">
        <mat-card-header>
          <mat-card-title>Add your property</mat-card-title><mat-icon style="font-size: 36px" [inline]="true">house</mat-icon>
          <mat-card-subtitle>Help the platform grow</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <div class="flex-row">
                <div class="temp">
                    <ul>
                        Want to add your property and be part of a bigger community? Send us a message in the <a routerLink="/info">contact</a> page. Together we stand, divided we fall!
                    </ul>        
                </div>
          </div>
        </mat-card-content>
    </mat-card>
</mat-grid-tile>
</mat-grid-list>
</div>