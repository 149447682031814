<form [formGroup]="registerForm">
  <h1 mat-dialog-title>Sign Up</h1>
        <mat-form-field>
          <mat-label>Email Address</mat-label>
          <input matInput formControlName="email" required>
          <mat-error *ngIf="f.email.errors?.required">An email address is required</mat-error>
          <mat-error *ngIf="f.email.errors?.email">The email address is not a correct format</mat-error>
        </mat-form-field>
      
        <mat-form-field>
          <mat-label>Password</mat-label>
          <input matInput type="password" formControlName="password" required>
          <mat-error *ngIf="f.password.errors?.required">A password is required</mat-error>
          <mat-error *ngIf="f.password.errors?.minlength">Password must be > 6 character long</mat-error>
        </mat-form-field>
        
        <mat-form-field>
          <mat-label>Confirm Password</mat-label>
          <input matInput type="password" formControlName="confirmPassword" required>
          <mat-error *ngIf="f.confirmPassword.errors?.required">A password is required</mat-error>
          <mat-error *ngIf="f.confirmPassword.errors?.mustMatch">Password should be same as Confirm password</mat-error>
        </mat-form-field>
        <div *ngIf="errorState">{{errorMessage}}</div>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="onSubmit()" cdkFocusInitial>Sign up</button>
</form>
