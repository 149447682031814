
<form [formGroup]="modalForm">
  <h1 mat-dialog-title>Login</h1>
  
    <button mat-raised-button (click)="loginWithGoogle()">Google</button>
    <button mat-raised-button (click)="loginWithFacebook()">Facebook</button>
    <button mat-raised-button (click)="loginWithYahoo()">Yahoo</button>

      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" required (click)="resetVariables()">
        <mat-error *ngIf="email.errors?.required">An email address is required</mat-error>
        <mat-error *ngIf="email.errors?.email">The email address is not a correct format</mat-error>
      </mat-form-field>

    <mat-form-field>
      <mat-label>Password</mat-label>
      <input matInput type="password" formControlName="password" required (click)="resetVariables()">
      <mat-error *ngIf="password.errors?.required">A password is required</mat-error>
    </mat-form-field>

    <div *ngIf="emailVerified == false">Email address is not verified</div>
    <div *ngIf="hasError">{{errorMessage}}</div>
    <div *ngIf="!mailSend" (click)="forgotPassword()">Forgot your password?</div>
    <div *ngIf="noMail">Fill in your email address.</div>
    <div *ngIf="mailSend">Check your mail for resetted password.</div>
   
      <button mat-raised-button (click)="onNoClick()">Cancel</button>
      <button mat-raised-button (click)="login()" cdkFocusInitial>Login</button>
      <button mat-raised-button (click)="register()">Sign Up</button>
  
</form>
