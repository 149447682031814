<div class="container" style="margin-top: 100px;">
    <h1>Reservation Confirmed</h1>
    <div *ngIf="reservation">
        <p>Thank you for your reservation, a confirmation mail has been sent to {{reservation.email}} with contact details.</p>
        <ul>
            <li><h2>booking details</h2></li>
            <li>{{reservation.apartment.name}}</li>
            <li>For {{reservation.adults}} people on the name of {{reservation["guest-name"]}}</li>
            <li>Reservation created on {{reservation["created-at"]}}</li>
            <li>Arrival date {{reservation.arrival}}, check in time {{reservation["check-in"]}}</li>
            <li>Departure date {{reservation.departure}}, check out time {{reservation["check-out"]}}</li>
            <li>Price to pay in cash upon arrival {{reservation.price}} EUR</li>
            <li>Extra comment: {{reservation.notice}}</li>
        </ul>
    </div>
</div>

