<div style="font-size: medium; margin-top: 100px" class="container">
    <h1>Terms and conditions</h1>
    <p>In making a reservation, you agree with the terms and conditions.</p>
    <h2>1. Booking/cancellation</h2>
    <p>
        Reservations made on this website are bound by word. There is no need for prepayment. Upon booking the house/apartment, we trust you keep us informed as soon as possible in case of cancellation.
    <br>
        There will be no charge in cancellation. 
        <br>
        For reassurance of your arrival, it would relieve us if you are answering mail, calls or messages sent.
        <br>
        Bookings made on this website are synchronised with other booking platforms. This means that your reservation is secured and cannot be overbooked.
        
    </p>
    
    <h2>
        2. In case of damage
    </h2>
    
    <p>
        The property you rent has to be taken care of as if it was your own house. Damage to the property will have to be reimbursed for it’s value. If a mutual agreement between owner and tenant is not found, we will acquire expertise (costs for the expertise will be split amongst both parties).
    <br>
        If there is damage due to usage, there will be no charge. (ex. Air Conditioning unit stopped working)
    </p>
    
    <h2>
        3. Payment
    </h2>
    <p>
        The payment for the rental is paid in cash upon arrival. In case this is not possible, please let us know when this could be settled at the earliest convenience.
    </p>
    
    <h2>
        4. Check in/out
    </h2>
    
    <p>
        Check in times: between 2pm and 2am. Check in after 2 am will be charged with an extra 20 euro.
    <br>
        Check out times: before 12pm. You can always check with the owner if there is a possibility to stay longer.
    </p>
    
    
    <h2>
        5. Amount of guests
    </h2>
    
    <p>
        If the amount of guests in the reservation does not correspond with reality, in some houses/apartments, there is an extra fee. If during the stay more people are staying overnight, this need to be mentioned so that the price can be adjusted accordingly.
    
    </p>
    <h2>
        6. Services
    </h2>
    
    <p>
        Vacation rental does not fall legally under the same conditions as hotels. We cannot offer the same services. Breakfast are not included. Cleaning ladies are arranged on voluntary bases depending on the owner’s will with no extra charge.
    
    </p>
    
</div>
